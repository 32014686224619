<template>
    <v-col cols="12" class="pa-0" id="tire-image-graph-page">
      <v-card max-height="500" class="mb-6" :loading="scanLoading">
          <template slot="progress">
            <v-progress-linear color="primary" indeterminate></v-progress-linear>
          </template>
          <v-toolbar color="primary" dark elevation="3" class="scan-title-bar">
            <v-toolbar-title class="text-section-title">Scan Image</v-toolbar-title>
          </v-toolbar>
          <v-skeleton-loader :loading="true" style="height: 530px;" v-if="scanLoading"
            type="image"
          ></v-skeleton-loader>
          <template v-else-if="!scanLoading && !model">
            <div class="ma-0 pa-5 pb-7 text-center">
                No Data Available!.
            </div>
          </template>
          <div class="py-5">
            <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }" class="ps"
                style="max-height: 450px">
              <template v-for="(row, index) in tireData">
                  <v-row no-gutters align="center" justify="center" class="ma-0" :key="index" >
                    <v-col cols="12" class="mb-6">
                        <v-row class="ma-0" no-gutters align="center" justify="space-around">
                          <v-col cols="5" class="d-flex align-center justify-center">
                              <v-menu  offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="image-view-container Image-edit-hover" v-bind="attrs" v-on="on" >
                                        <v-lazy>
                                            <img width="620" cover :id="`annoatate-left-${index}`" :src="row.L[0].imageFileUrl" style="max-height: 100px;"
                                                class="cursor-pointer" crossorigin="anonymous" v-bind="attrs" v-on="on"/>
                                        </v-lazy>
                                    </div>
                                </template>
                                <v-list class="scan-edit-options">
                                    <v-list-item class="cursor-pointer option" @click="show(row.L[0].imageFileUrl)">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-archive-eye-outline </v-icon>
                                            View
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item class="cursor-pointer option" @click="openCrop = row.L[0].imageFileUrl">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-crop</v-icon>
                                            Crop
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item class="cursor-pointer option" @click="showMarkerArea(`annoatate-left-${index}`)">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-image-plus </v-icon>
                                            Annotation
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                          </v-col>
                          <v-col cols="5" class="d-flex align-center justify-center">
                            <v-menu  offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="image-view-container Image-edit-hover" v-bind="attrs" v-on="on" >
                                        <v-lazy>
                                            <img width="620" cover :id="`annoatate-right-${index}`" :src="row.R[0].imageFileUrl" style="max-height: 100px;"
                                                class="cursor-pointer" crossorigin="anonymous" v-bind="attrs" v-on="on"/>
                                        </v-lazy>
                                    </div>
                                </template>
                                <v-list class="scan-edit-options">
                                    <v-list-item class="cursor-pointer option" @click="show(row.R[0].imageFileUrl)">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-archive-eye-outline </v-icon>
                                            View
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item class="cursor-pointer option" @click="openCrop = row.R[0].imageFileUrl">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-crop</v-icon>
                                            Crop
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item class="cursor-pointer option" @click="showMarkerArea(`annoatate-right-${index}`)">
                                        <v-list-item-title>
                                            <v-icon color="primary" class="mr-2">mdi-image-plus </v-icon>
                                            Annotation
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                    </v-col>
                  </v-row>
              </template>
            </vue-perfect-scrollbar>
          </div>
        </v-card>
        <CropperModal :openCrop="openCrop" v-if="!scanLoading && openCrop" @cancel="openCrop = null"
            @cropped="$emit('editedAnnotate', $event); openCrop = null" :name="'Cropped'"/>
      </v-col>
  </template>

  <script>
  import {mapGetters} from 'vuex';
  import _ from 'lodash';
import * as markerjs2 from "markerjs2";

  import tireMeasures from '../../service/tire-data';

  export default {
    props: ['model'],
    emits: ['editedAnnotate'],
    components: {
        CropperModal: () => import("../CropperModal.vue"),
    },
    data: (vm) => ({
        tireData: null,
        openCrop: null,
    }),
    computed: {
      ...mapGetters(['scanLoading'])
    },
    watch: {
      model(value) {
        if (value) this.tireData = tireMeasures(value);
      }
    },
    created() {
      if (this.model) this.tireData = tireMeasures(this.model);
    },
    methods: {
        show(value) {
            const image = [];
            image.push(value);
            this.$viewerApi({
                images: image
            })
        },
        showMarkerArea(imgId) {
            const target = document.getElementById(imgId);
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.renderImageType = 'image/jpeg';
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener("render", (event) => {
                this.$store.commit('setPageLoading', false);
                this.$emit('editedAnnotate', {value: event.dataUrl, option: 'Annotated'});
            });
            markerArea.show();
        },
    }
  }
  </script>